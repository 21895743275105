import api from '@/services/api'

export default {
  getAll(params) {
    let queryString = `?page=${params.page || 1}`;

    if (params.term) {
      queryString += `&term=${params.term}`;
    }

    if (params.startDate) {
      const startDate = new Date(params.startDate);
      queryString += `&startDate=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
    }

    if (params.endDate) {
      const endDate = new Date(params.endDate);
      queryString += `&endDate=${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
    }

    return api.get(`/activities${queryString}`);
  },

  create(data) {
    return api.post('/activities', data);
  },

  exportActivities(params) {
    let queryString = '?export=true';

    if (params.term) {
      queryString += `&term=${params.term}`;
    }

    if (params.startDate) {
      const startDate = new Date(params.startDate);
      queryString += `&startDate=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
    }

    if (params.endDate) {
      const endDate = new Date(params.endDate);
      queryString += `&endDate=${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
    }

    return api({
      url: `/activities${queryString}`,
      method: 'GET',
      responseType: 'blob',
    })
  },
}